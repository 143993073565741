import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Loan } from '@matech/thebigpos-sdk'
import { useVenti } from 'venti'
import { useAlert } from '../hooks'
import queryKeys from '../services/queryKeys'
import { TheBigPOSApi } from '../lib/TheBigPOSClient'
import { LoanSignalRContextProvider } from './LoanSignalRContext'
import { LoanData } from '../types'
import { getTheme } from '../config'
import useUserAgent from '../hooks/useUserAgent'

export type LoanContextValue = {
	loan?: Loan
	loanData?: LoanData
	isLoading: boolean
	isRefetching: boolean
	refetch?: () => void
}

export type LoanContextProviderProps = {
	children: React.ReactNode
}

const defaultContextValues: LoanContextValue = {
	isLoading: false,
	isRefetching: false,
}

export const LoanContext = React.createContext(defaultContextValues)

const theme = getTheme()

export const LoanContextProvider = ({
	children,
}: LoanContextProviderProps) => {
	const { loanId: loanIdParam } = useParams()
	const { alert } = useAlert()
	const { mobileOs } = useUserAgent()
	const ventiState = useVenti()
	const ventiLoanId = ventiState.get(
		theme.storageKeys.editingLoanApplicationId
	)

	const loanId = loanIdParam || ventiLoanId

	const {
		isLoading,
		isRefetching,
		data,
		refetch: refetchLoan,
		error,
	} = useQuery({
		queryKey: [queryKeys.loan, loanId],
		queryFn: () => TheBigPOSApi.getLoan(loanId as string),
		enabled: !!loanId,
	})

	const {
		isLoading: isLoanDataLoading,
		isRefetching: isLoanDataRefetching,
		data: loanData,
		refetch: refetchLoanData,
		error: loanDataError,
	} = useQuery({
		queryKey: [queryKeys.loanData, loanId],
		queryFn: () => TheBigPOSApi.getLoanData(loanId as string),
		enabled: !!loanId,
		refetchOnWindowFocus: mobileOs !== 'android',
	})

	useEffect(() => {
		ventiState.set(theme.storageKeys.loanData, loanData?.data)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loanData])

	const refetch = useCallback(async () => {
		await refetchLoan()
		await refetchLoanData()
	}, [refetchLoan, refetchLoanData])

	const value: LoanContextValue = useMemo(
		() => ({
			loan: data?.data,
			loanData: loanData?.data,
			isLoading: isLoading || isLoanDataLoading,
			isRefetching: isRefetching || isLoanDataRefetching,
			refetch,
		}),
		[
			data?.data,
			isLoading,
			isLoanDataLoading,
			isLoanDataRefetching,
			isRefetching,
			loanData?.data,
			refetch,
		]
	)

	useEffect(() => {
		if ((error && data?.status !== 404) || loanDataError) {
			const errorMessage =
				error?.message ||
				loanDataError?.message ||
				'There was a problem fetching the loan'
			alert(errorMessage, {
				severity: 'error',
			})
		}
	}, [alert, data?.status, error, loanDataError])

	return (
		<LoanSignalRContextProvider>
			<LoanContext.Provider value={value}>
				{children}
			</LoanContext.Provider>
		</LoanSignalRContextProvider>
	)
}

export const useLoanContext = () => useContext(LoanContext)
