export default {
	global: {
		yes: 'Yes',
		no: 'No',
		email: 'Email',
		password: 'Password',
		resend: 'Resend',
		view: 'View',
		delete: 'Delete',
		created: 'Created',
		status: 'Status',
		reason: 'Reason',
		type: 'Type',
		details: 'Details',
		username: 'Username',
		retry: 'Retry',
		data: 'Data',
	},
	auth: {
		sign_in: 'Sign In',
		dont_have_an_account: "Don't have an account?",
		check_inbox_for_invite_from_loan_officer:
			'Please check your email inbox for an invite from your Loan Officer',
		forgot_my_password: 'I Forgot My Password',
		existing_user_account_detected: 'Existing user account detected',
		sign_in_to_account: 'Sign in to your account',
	},
	loans: {
		loan_id: 'Loan ID',
		not_assigned: 'Not Assigned',
	},
	loan_queue: {
		loan_queue: 'Loan Queue',
		loan_queue_record: 'Loan Queue Record',
		type: {
			new_loan: 'New Loan',
			new_cob_loan: 'New Co-Borrower Loan',
			loan_update: 'Update Application',
			field_update: 'Field Update',
			document_upload: 'Document Upload',
			new_buckets: 'New Buckets',
		},
		job_id: 'Job ID',
	},
	users: {
		loan_officer: 'Loan Officer',
	},
	validation: {
		email: {
			required: 'Email is required',
			invalid: 'Email is invalid',
		},
		password: {
			required: 'Password is required',
			minLength: 'Password must be at least {minLength} characters',
		},
	},
	errors: {
		general: 'There was an error processing the request',
		error_loading:
			'There was a problem retrieving the information requested',
		invalid_credentials: 'Invalid username and/or password',
		account_doesnt_have_access_to_env:
			'Your account does not have access to this environment',
		error_retrieving_auth: 'Error retrieving authentication info',
	},
	alerts: {
		save_success: 'The {name} successfully updated',
		delete_success: 'The {name} successfully deleted',
	},
}
