import { useVenti } from 'venti'
import { isEqual } from 'lodash'
import { useTheme } from '@mui/material/styles'
import SwipeableViews from 'react-swipeable-views'
import React, { useState, useEffect, useRef } from 'react'
import { Tabs, Tab, Card, CardContent } from '@mui/material'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'

import { SiteTypes } from '../../../services/client'
import { ProfileView } from './Profile'
import { getTheme } from '../../../config'
import Page from '../../../components/Page'
import withAuth from '../../../components/withAuth'
import queryKeys from '../../../services/queryKeys'
import { a11yProps } from '../../../services/helper'
import { Loading } from '../../../components/Loading'
import { LoadingBtn } from '../../../components/Button'
import { useWindowSize, useAlert } from '../../../hooks'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'
import { siteConfigurationModel } from '../../../services/utils'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import { Integrations } from '../../../components/admin/Integrations'
import AccountSettings from '../../../components/admin/AccountSettings'
import MilestoneConfigurations from '../../../components/admin/MilestoneConfigurations'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'

const theme = getTheme()

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <div className="mt-5">{children}</div>}
		</div>
	)
}

const AccountEdit = ({ navigation }) => {
	const { alert } = useAlert()
	const [tab, setTab] = useState(0)
	const queryClient = useQueryClient()
	const themeMUI = useTheme()
	const [width] = useWindowSize()
	const ventiState = useVenti()
	const mixpanel = useMixpanel()

	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: '',
		leavePage: false,
		canBeSaved: false,
		isSave: false,
		event: null,
	})
	const [initialState, setInitialState] = useState(
		siteConfigurationModel
	)
	const [formData, setFormData] = useState({
		...siteConfigurationModel,
		entityType: SiteTypes.admin,
	})
	const [canBeSubmitted, setCanBeSubmitted] = useState(true)
	const initialRender = useRef(true)

	const { error, data: customerSiteConfigurationsResult } = useQuery({
		queryKey: [queryKeys.customerSiteConfigurations],
		queryFn: TheBigPOSApi.getSiteConfigurationByAccount,
		retry: 2,
		refetchOnWindowFocus: false,
	})

	useEffect(() => {
		const canBeSubmitted =
			formData.url.trim() !== '' && !isEqual(initialState, formData)
		setCanBeSubmitted(canBeSubmitted)

		if (initialRender.current) {
			initialRender.current = false
		}
	}, [formData, initialState])

	useEffect(() => {
		if (error) {
			alert(
				'There was a problem loading the customer site configurations',
				{
					severity: 'error',
				}
			)
		}
	}, [error])

	useEffect(() => {
		if (customerSiteConfigurationsResult?.data) {
			ventiState.set(
				theme.storageKeys.account,
				customerSiteConfigurationsResult.data
			)
			setFormData(customerSiteConfigurationsResult.data)
			setInitialState(customerSiteConfigurationsResult.data)
		}
	}, [customerSiteConfigurationsResult])

	const updateSiteConfigurationMutation = useMutation({
		mutationFn: (data) =>
			TheBigPOSApi.updateSiteConfigurationForAccount(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: queryKeys.customerSiteConfigurations,
			})
		},
	})

	const saveForm = async () => {
		await updateSiteConfigurationMutation.mutateAsync({
			...ventiState.get(theme.storageKeys.account),
			...formData,
		})
		setInitialState(formData)
		setCanBeSubmitted(false)
		mixpanel.trackEvent(eventTypes.ACCOUNT_SETTINGS_UPDATED)
		alert('Account successfully updated')
	}

	const modalConfirm = async (modalConfirmation) => {
		modalConfirmation.leavePage
			? navigation.dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation({
					open: false,
					text: '',
					leavePage: false,
				})

		await updateSiteConfigurationMutation.mutateAsync({
			...ventiState.get(theme.storageKeys.account),
			...formData,
		})
		mixpanel.trackEvent(eventTypes.ACCOUNT_SETTINGS_UPDATED)
		alert('Account successfully updated')
	}

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	return (
		<Page
			page="app-profile"
			title="Account Settings"
			isFullWidth={true}
		>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
				saveForm={saveForm}
			/>

			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
					Account Settings
				</p>
				{!formData.id ? (
					<div
						className="flex justify-center dark:bg-[#121212]"
						style={{ height: `calc(100vh - 216px)` }}
					>
						<Loading size="small" />
					</div>
				) : (
					<>
						<div className="flex flex-wrap sm:flex-row justify-end mb-5">
							<LoadingBtn
								id={`AdminAccountEditSaveButton`}
								disabled={!canBeSubmitted}
								loading={updateSiteConfigurationMutation.isPending}
								text="Update Account"
								onClick={saveForm}
								fullWidth={false}
							/>
						</div>
						<Tabs
							variant="scrollable"
							scrollButtons="auto"
							value={tab}
							onChange={handleTabChange}
							aria-label="Tabs"
						>
							<Tab label="Account Profile" {...a11yProps(0)} />
							<Tab label="Integrations" {...a11yProps(1)} />
							<Tab
								label="Milestone Configurations"
								{...a11yProps(2)}
							/>
							<Tab label="Settings" {...a11yProps(3)} />
						</Tabs>
						<SwipeableViews
							axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
							index={tab}
							onChangeIndex={handleChangeIndex}
						>
							<TabPanel
								value={tab}
								index={0}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<ProfileView
											formData={formData}
											setFormData={setFormData}
											width={width}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={tab}
								index={1}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<Integrations
											formData={formData}
											setFormData={setFormData}
											entityType={0}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={tab}
								index={2}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<MilestoneConfigurations />
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={tab}
								index={3}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<AccountSettings />
									</CardContent>
								</Card>
							</TabPanel>
						</SwipeableViews>
					</>
				)}
			</div>
		</Page>
	)
}
export default withAuth(AccountEdit)
