import Dialog from '@mui/material/Dialog'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Button, LoadingBtn } from '../Button'
import { Checkbox, FormControlLabel } from '@mui/material'
import { useState } from 'react'
import { Warning } from '@mui/icons-material'

export const ModalRemoveRestoreRecordConfirm = (props) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

	const closeModal = () => {
		setNukeConfirm(false)
		props.setRemoveModalVisible(false)
	}

	const [nukeConfirm, setNukeConfirm] = useState(false)

	return (
		<Dialog
			open={props.removeModalVisible}
			onClose={closeModal}
			aria-labelledby="responsive-dialog-title"
		>
			<div className="flex flex-col items-center px-6 md:max-w-lg py-8 md:px-10">
				<p className="pb-5 text-2xl text-center font-bold">
					Confirm{' '}
					{!props.restore
						? props.row?.nuke
							? 'Permanent Deletion'
							: 'Delete'
						: 'Reactivation'}
				</p>
				{props.row?.nuke && (
					<div className="text-rose-800 text-center">
						<p className="mb-6">
							<Warning sx={{ fontSize: 60 }} />
						</p>
						<p className="font-lg mb-5 md:mx-6 text-center font-bold text-xl">
							WARNING: THIS CAN NOT BE UNDONE
						</p>
					</div>
				)}
				<p className="text-xl mb-5 md:mx-10 text-center">
					{!props.restore
						? props.row?.nuke
							? 'Permanently Delete'
							: 'Remove'
						: 'Restore'}{' '}
					"
					{props.row?.name ||
						props.row?.Name ||
						props.name ||
						'Record'}
					"{props.row?.nuke ? ' & All Associated Data?' : '?'}
				</p>
				{props.row?.nuke && (
					<div className="mb-10">
						<FormControlLabel
							className={`text-sm`}
							control={
								<Checkbox
									value={nukeConfirm}
									onChange={(e) => setNukeConfirm(e.target.checked)}
								/>
							}
							label={`
								By checking this box I confirm that I understand that this action can not be undone.
							`}
						/>
					</div>
				)}
				<LoadingBtn
					id="ModalRemoveConfirmRemoveButton"
					text={`Yes, ${!props.restore ? 'Remove' : 'Restore'} It`}
					onClick={() =>
						!props.restore ? props.remove() : props.restore()
					}
					style={
						fullScreen
							? { width: '90%', marginBottom: 10 }
							: { width: 290, marginBottom: 10 }
					}
					variant="contained"
					loading={props.loading}
					disabled={props.row?.nuke === true && !nukeConfirm}
				/>
				<Button
					className="dark:text-white"
					id="ModalRemoveConfirmCloseButton"
					text="Cancel"
					onClick={closeModal}
					style={fullScreen ? { width: '90%' } : { width: 290 }}
					variant="outlined"
				/>
			</div>
		</Dialog>
	)
}
