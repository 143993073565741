import React, { useState, useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views'
import Tab from '@mui/material/Tab'
import { useAppContext } from '../../../components/AppContext'
import { LoanInfo } from '../../../components/loanAppDetails/LoanInfo'
import { PersonalInfo } from '../../../components/loanAppDetails/PersonalInfo'
import { RealEstateOwned } from '../../../components/loanAppDetails/RealEstateOwned'
import { EmploymentIncome } from '../../../components/loanAppDetails/EmploymentIncome'
import { ModalPleaseWait } from '../../../components/modals/ModalPleaseWait'
import { Assets } from '../../../components/loanAppDetails/Assets'
import { Roles } from '../../../services/client'
import Tabs from '@mui/material/Tabs'
import { a11yProps } from '../../../services/helper'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import RefreshPNG from 'assets/lordicons/Refresh.png'
import RefreshGIF from 'assets/lordicons/RefreshAnimated.gif'
import RefreshDarkPNG from 'assets/lordicons/RefreshDark.png'
import RefreshDarkGIF from 'assets/lordicons/RefreshAnimatedDark.gif'
import { useTheme } from '@mui/material/styles'
import TabPanel from '../../../components/TabPanel'
import LoanStatus from '../../../components/loanAppDetails/LoanStatus'
import LoanUsersTable from '../../../components/loanAppDetails/LoanUsersTable'
import LoanQueueList from '../../../components/LoanQueue/LoanQueueList'
import useUser from '../../../hooks/useUser'
import { useLoanContext } from '../../../contexts/LoanContext'
import { useQueryClient } from '@tanstack/react-query'
import queryKeys from '../../../services/queryKeys'

const bgColor = {
	'&:hover': {
		backgroundColor: 'transparent',
	},
}

function LoanDetailsContent({ className = '' }) {
	const { isLoanAdmin } = useUser()
	const { loanData, isLoading, isRefetching, refetch } =
		useLoanContext()

	const { state } = useAppContext()
	const { siteConfig, user } = state

	const queryClient = useQueryClient()

	const [tab, setTab] = useState(
		siteConfig.enabledServices.loanStatus ? 0 : 1
	)
	const [tabs, setTabs] = useState([])

	const themeMUI = useTheme()

	useEffect(() => {
		let routes = []

		if (siteConfig.enabledServices.loanStatus) {
			routes.push({
				key: 'loanStatus',
				title: 'Loan Status',
				index: 0,
			})
		}

		routes = [
			...routes,
			{
				key: 'loanInformation',
				title: 'Loan and Property',
				index: 1,
			},
			{
				key: 'personalInformation',
				title: 'Personal Information',
				index: 2,
			},
		]

		if (![Roles.realtor].includes(user.role)) {
			routes = [
				...routes,
				...[
					{
						key: 'currentEmployerAndAdditionalIncomeSource',
						title: 'Employment',
						index: 3,
					},
					{ key: 'assetAccount', title: 'Asset Accounts', index: 4 },
					{ key: 'realEstateOwned', title: 'Real Estate', index: 5 },
					// { key: 'declarations', title: 'Declarations', index: 6 },
					// { key: 'governmentMonitoring', title: 'Government Monitoring', index: 7 }
				],
			]
		}

		routes.push({
			key: 'people',
			title: 'People',
			index: 6,
		})

		if (isLoanAdmin) {
			routes.push({
				key: 'queue',
				title: 'Loan Queue',
				index: 7,
			})
		}

		setTabs(routes)
	}, [user])

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const handleOnClick = async () => {
		await refetch()
		await queryClient.invalidateQueries({
			queryKey: [queryKeys.loanQueue],
		})

		await queryClient.invalidateQueries({
			queryKey: [queryKeys.loanUsers],
		})
	}

	const isLoadingOrRefetching = isLoading || isRefetching

	return (
		<div className={className}>
			<div className="flex items-center">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 dark:text-white">
					Loan Details
				</p>
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="refresh"
					onClick={handleOnClick}
					disabled={isLoadingOrRefetching}
					sx={bgColor}
				>
					<img
						className="w-14 mt-1"
						src={
							themeMUI.palette.mode === 'light'
								? RefreshPNG
								: RefreshDarkPNG
						}
						onMouseOver={(e) =>
							(e.currentTarget.src =
								themeMUI.palette.mode === 'light'
									? RefreshGIF
									: RefreshDarkGIF)
						}
						onMouseOut={(e) =>
							(e.currentTarget.src =
								themeMUI.palette.mode === 'light'
									? RefreshPNG
									: RefreshDarkPNG)
						}
						alt="refresh"
					/>
				</IconButton>
			</div>

			<ModalPleaseWait
				logo={siteConfig.logoUrl}
				visible={
					isLoadingOrRefetching &&
					[Roles.loanOfficer, Roles.branchManager].includes(user.role)
				}
				message={`Fetching loan information`}
			/>

			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				value={tab}
				onChange={handleTabChange}
				aria-label="Tabs"
			>
				{tabs.map((route) => (
					<Tab
						value={route.index}
						label={route.title}
						key={route.key}
						{...a11yProps(route.index)}
					/>
				))}
			</Tabs>
			<SwipeableViews
				axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={tab}
				onChangeIndex={handleChangeIndex}
			>
				{siteConfig.enabledServices.loanStatus && (
					<TabPanel value={tab} index={0} dir={themeMUI.direction}>
						<Card className="min-h-screen overflow-auto">
							<CardContent>
								{!isLoadingOrRefetching &&
									loanData &&
									Object.keys(loanData).length > 0 && (
										<LoanStatus loan={loanData} />
									)}
							</CardContent>
						</Card>
					</TabPanel>
				)}
				<TabPanel value={tab} index={1} dir={themeMUI.direction}>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							<LoanInfo
								data={loanData}
								loading={isLoadingOrRefetching}
							/>
						</CardContent>
					</Card>
				</TabPanel>
				{/*<TabPanel value={tab} index={1}>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							<PropertyInfo data={loanData} loading={fetching} />
						</CardContent>
					</Card>
				</TabPanel>*/}
				<TabPanel value={tab} index={2} dir={themeMUI.direction}>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							{loanData && (
								<PersonalInfo
									data={loanData}
									loading={isLoadingOrRefetching}
								/>
							)}
						</CardContent>
					</Card>
				</TabPanel>
				<TabPanel value={tab} index={3} dir={themeMUI.direction}>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							{loanData && (
								<>
									{[0, 1, 2].map((index) => (
										<EmploymentIncome
											key={`Employment${index}`}
											data={loanData}
											loading={isLoadingOrRefetching}
											index={index}
										/>
									))}
								</>
							)}
						</CardContent>
					</Card>
				</TabPanel>
				<TabPanel value={tab} index={4} dir={themeMUI.direction}>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							{loanData && (
								<>
									{[0, 1, 2].map((index) => (
										<Assets
											key={`AssetAccount${index}`}
											data={loanData}
											loading={isLoadingOrRefetching}
											index={index}
										/>
									))}
								</>
							)}
						</CardContent>
					</Card>
				</TabPanel>
				<TabPanel value={tab} index={5} dir={themeMUI.direction}>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							{loanData && (
								<>
									{[0, 1, 2].map((index) => (
										<RealEstateOwned
											key={`RealEstateOwned${index}`}
											data={loanData}
											loading={isLoadingOrRefetching}
											index={index}
										/>
									))}
								</>
							)}
						</CardContent>
					</Card>
				</TabPanel>
				<TabPanel value={tab} index={6} dir={themeMUI.direction}>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							<LoanUsersTable loanId={loanData?.LoanID} />
						</CardContent>
					</Card>
				</TabPanel>
				<TabPanel value={tab} index={7} dir={themeMUI.direction}>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							<LoanQueueList
								loanId={loanData?.LoanID}
								showLoanOfficer={false}
							/>
						</CardContent>
					</Card>
				</TabPanel>
			</SwipeableViews>
		</div>
	)
}

export default LoanDetailsContent
