import React from 'react'
import Dialog, { DialogProps } from './Dialog'
import CodeEditor, { CodeEditorProps } from '../CodeEditor'

export type ModalCodeEditorProps = {
	codeEditor: CodeEditorProps
} & DialogProps

const ModalCodeEditor = ({
	codeEditor,
	...rest
}: ModalCodeEditorProps) => {
	return (
		<Dialog {...rest}>
			<CodeEditor {...codeEditor} />
		</Dialog>
	)
}

export default ModalCodeEditor
