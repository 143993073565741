/* eslint-disable camelcase, no-param-reassign */
import { useVenti } from 'venti'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import { UpdateMeRequest } from '@matech/thebigpos-sdk'
import { useAppContext } from '../components/AppContext'
import { getRouteFromInvite } from '../services/utils'
import { navigationLinking } from '../services/navigation'
import { Roles } from '../services/client'
import { useAppContextActions } from '../components/AppContext/AppHooks'
import { getTheme } from '../config'
import { useMixpanel } from './useMixpanel'
import {
	TheBigPOSApi,
	setBearerToken as setPOSApiBearerToken,
} from '../lib/TheBigPOSClient'
import {
	AppContextState,
	AuthData,
	LoanData,
	UserWithAuth,
} from '../types'

const theme = getTheme()
const useUser = () => {
	const adminRoles = useMemo(
		() => [Roles.admin, Roles.superAdmin],
		[]
	)
	const { state } = useAppContext()
	const { applyAuthToken, applyUser } = useAppContextActions()
	const ventiState = useVenti()
	const {
		user: currentUser,
		siteConfig,
		redirectRoute,
		invite,
	} = state as unknown as AppContextState
	const isLoggedIn: boolean = currentUser?.isLoggedIn || false
	const navigate = useNavigate()
	const location = useLocation()
	const mixpanel = useMixpanel()

	const postLoanData: LoanData =
		ventiState.get(theme.storageKeys.postLoanData) || {}

	const getSignInRedirectURL = (user?: UserWithAuth) => {
		if (location.state?.from) {
			return location.state.from
		}

		const signInUser = user || currentUser
		let redirectRouteFromSignIn =
			redirectRoute || navigationLinking.Portal

		if (invite?.emailAddress) {
			return getRouteFromInvite(invite, siteConfig)
		}
		const loRoles = [Roles.loanOfficer, Roles.branchManager]
		const isAppRedirect =
			redirectRouteFromSignIn.includes('Apply') ||
			redirectRouteFromSignIn.includes('Prequalify')
		if (
			loRoles.includes(signInUser.role) &&
			isAppRedirect &&
			!redirectRouteFromSignIn.includes('LO')
		) {
			redirectRouteFromSignIn = `LO${redirectRouteFromSignIn}`
		}

		if (
			// @ts-ignore
			!navigationLinking[redirectRouteFromSignIn] ||
			adminRoles.includes(signInUser.role)
		) {
			redirectRouteFromSignIn = navigationLinking.Portal
		}
		return `/${redirectRouteFromSignIn}`
	}

	const setUser = (data: UserWithAuth) => {
		applyUser(data)
		ventiState.set(theme.storageKeys.user, data)
		return data
	}

	const setAuthToken = (token: string, expiresIn: number) => {
		setPOSApiBearerToken(token)
		applyAuthToken(token)
		ventiState.set(theme.storageKeys.authToken, token)

		const authTokenExp = new Date()
		authTokenExp.setMinutes(
			authTokenExp.getMinutes() + expiresIn / 60 - 5
		)
		ventiState.set(theme.storageKeys.authTokenExp, authTokenExp)
	}

	const initUser = async (
		authData: AuthData,
		redirectOnSuccess = true
	) => {
		const { access_token: token, expires_in } = authData
		setAuthToken(token, expires_in)

		const expirationInMilliseconds = expires_in * 1000
		const tokenExp = ventiState.set(
			theme.storageKeys.authTokenExp,
			new Date(new Date().getTime() + expirationInMilliseconds)
		)

		authData.isLoggedIn = true

		const getMeResponse = await TheBigPOSApi.getMe()
		const me = getMeResponse.data
		mixpanel.identifyAndSetUser(me, siteConfig)

		if (
			[Roles.loanOfficer, Roles.branchManager].includes(me.role) &&
			me.id !== siteConfig.id &&
			!window.location.hostname.includes('localhost')
		) {
			const getLoanOfficerResponse =
				await TheBigPOSApi.getLoanOfficer(me.id)
			const loanOfficer = getLoanOfficerResponse.data
			// TODO: if more than one siteConfigurationurl we should show a list to choose
			if (
				!loanOfficer.siteConfigurations.some(
					(s) => s.id === siteConfig.id
				)
			) {
				window.open(
					`https://${loanOfficer.siteConfigurations[0].url}`,
					'_blank',
					'noopener,noreferrer'
				)
				return
			}
		}

		authData.tokenExp = tokenExp
		const userData = {
			...authData,
			...me,
		}

		const user = setUser(userData)

		if (siteConfig.mfaPreference === 'Required' && !me.mfaEnabled) {
			navigate(`/${navigationLinking.SMSVerification}`)
			return
		}

		if (redirectOnSuccess) {
			const redirectURL = getSignInRedirectURL(user)
			navigate(redirectURL)
		}

		// If there's loan data to send, send it now
		if (postLoanData.BorrowerEmail) {
			ventiState.set(theme.storageKeys.appPosting, true)
			ventiState.set(theme.storageKeys.appSubmitted, true)
			const createLoanResponse =
				await TheBigPOSApi.createLoan(postLoanData)
			const loanId = createLoanResponse.data
			ventiState.set(theme.storageKeys.loanId, loanId)
			ventiState.set(theme.storageKeys.appPosting, false)
			ventiState.unset(theme.storageKeys.postLoanData)
		}
	}

	const handleUpdateMe = async (data: UpdateMeRequest) => {
		await TheBigPOSApi.replaceMe(data)
		applyUser(data)
	}

	const handleUpdateMyPhone = async (phone: string) => {
		await TheBigPOSApi.updateMyPhone({ phone })
		applyUser({ ...currentUser, phone })
	}

	const isLoanOfficer: boolean = useMemo(
		() => currentUser && currentUser.role === Roles.loanOfficer,
		[currentUser]
	)

	const isBorrower: boolean = useMemo(
		() => currentUser && currentUser.role === Roles.borrower,
		[currentUser]
	)

	const isRealtor: boolean = useMemo(
		() => currentUser && currentUser.role === Roles.realtor,
		[currentUser]
	)

	const isAdmin: boolean = useMemo(
		() => currentUser && adminRoles.includes(currentUser.role),
		[adminRoles, currentUser]
	)

	const isSuperAdmin: boolean = useMemo(
		() => currentUser && currentUser.role === Roles.superAdmin,
		[currentUser]
	)

	const isSettlementAgent: boolean = useMemo(
		() => currentUser && currentUser.role === Roles.settlementAgent,
		[currentUser]
	)

	const isLoanAdmin: boolean = useMemo(() => {
		const roles = [
			Roles.loanOfficer,
			Roles.branchManager,
			Roles.loanProcessor,
			Roles.loanOfficerAssistant,
		]
		return currentUser && roles.includes(currentUser.role)
	}, [currentUser])

	const canUseTasks: boolean = useMemo(() => {
		const taskRoles: string[] = [
			Roles.borrower,
			Roles.branchManager,
			Roles.loanOfficer,
			Roles.loanProcessor,
			Roles.loanOfficerAssistant,
		]
		return currentUser && taskRoles.includes(currentUser.role)
	}, [currentUser])

	const canImpersonate: boolean = useMemo(() => {
		return (
			currentUser?.role === Roles.superAdmin ||
			(currentUser?.role === Roles.admin &&
				currentUser?.canImpersonate)
		)
	}, [currentUser])

	return {
		user: currentUser,
		isLoggedIn,
		getSignInRedirectURL,
		setUser,
		setAuthToken,
		initUser,
		updateMe: handleUpdateMe,
		updateMyPhone: handleUpdateMyPhone,
		isLoanOfficer,
		isBorrower,
		isRealtor,
		isAdmin,
		isSuperAdmin,
		isLoanAdmin,
		isSettlementAgent,
		canUseTasks,
		adminRoles,
		canImpersonate,
	}
}

export default useUser
