import React, { useState } from 'react'
import { Pagination } from '@mui/material'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { TaskComment } from '@matech/thebigpos-sdk'
import queryKeys from '../../../../services/queryKeys'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'
import { useAppContext } from '../../../../components/AppContext'
import { useAlert } from '../../../../hooks'
import LoadingDots from '../../../../components/LoadingDots'
import { ModalRemoveRestoreRecordConfirm } from '../../../../components/modals/ModalRemoveRestoreRecordConfirm'
import { getTheme } from '../../../../config'
import TaskCommentModal from './TaskCommentModal'
import ChatBubble from './ChatBubble'
import LeaveCommentButton from './LeaveCommentButton'

type CommentPaginatedListProps = {
	userLoanTaskId: string
	loanId: string
	hasComments: boolean
	onCommentSubmit: () => void
}

const theme = getTheme()

const CommentPaginatedList = ({
	userLoanTaskId,
	loanId,
	hasComments,
	onCommentSubmit,
}: CommentPaginatedListProps) => {
	const [currentPage, setCurrentPage] = useState(1)
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [isCommentModalOpen, setIsCommentModalOpen] = useState(false)
	const [selectedRow, setSelectedRow] = useState<TaskComment | null>(
		null
	)

	const [searchCriteria] = useState({})
	const { state } = useAppContext()
	const { user } = state
	const { alert } = useAlert()
	const queryClient = useQueryClient()

	const { defaultPageSize } = theme.pagination

	const { data: commentsResult, isFetching } = useQuery({
		queryKey: [
			queryKeys.userLoanTaskComments,
			userLoanTaskId,
			loanId,
			currentPage,
		],
		queryFn: () =>
			TheBigPOSApi.searchLoanTaskComments(
				loanId,
				userLoanTaskId,
				searchCriteria,
				{
					pageNumber: currentPage,
					pageSize: defaultPageSize,
				}
			),
		enabled: !!userLoanTaskId && !!loanId && hasComments,
	})

	const removeCommentMutation = useMutation({
		mutationFn: async (data: string) =>
			TheBigPOSApi.deleteLoanTaskComment(
				loanId,
				userLoanTaskId,
				data
			),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.userLoanTaskComments],
			})
			alert(`Comment successfully deleted`)
			setSelectedRow(null)
			setRemoveModalVisible(false)
			onCommentSubmit()
		},
	})

	const handleDeleteClick = async () => {
		if (!selectedRow?.id) return
		await removeCommentMutation.mutateAsync(selectedRow.id)
	}

	const confirmDelete = (comment: TaskComment) => {
		setRemoveModalVisible(true)
		setSelectedRow(comment)
	}

	const handleButtonClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		event.stopPropagation()
		event.preventDefault()
		setIsCommentModalOpen(true)
	}

	const handleConfirm = () => {
		onCommentSubmit()
		alert(`Comment successfully added`)
	}

	return (
		<div className="w-full p-2">
			<TaskCommentModal
				open={isCommentModalOpen}
				onClose={() => {
					setIsCommentModalOpen(false)
				}}
				userLoanTaskId={userLoanTaskId}
				loanId={loanId}
				isRejecting={false}
				handleConfirm={handleConfirm}
			/>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={removeModalVisible}
				setRemoveModalVisible={setRemoveModalVisible}
				remove={handleDeleteClick}
				row={selectedRow}
				loading={removeCommentMutation.isPending}
				name="comment"
			/>
			{isFetching ? (
				<div className="flex flex-col items-center w-full">
					<LoadingDots className="w-48" />
				</div>
			) : (
				(commentsResult?.data?.rows || []).map(
					(comment: TaskComment) => (
						<ChatBubble
							comment={comment}
							createdByMe={comment.createdBy.id === (user?.id || '')}
							onConfirmDelete={confirmDelete}
							key={comment.id}
						/>
					)
				)
			)}
			{(commentsResult?.data?.count || 0) === 0 && !isFetching && (
				<p>
					No comments found.{' '}
					{user?.role !== 'Borrower' && (
						<LeaveCommentButton
							title="Add a comment now"
							onClick={handleButtonClick}
						/>
					)}
				</p>
			)}
			{!isFetching && (commentsResult?.data?.count || 0) !== 0 && (
				<div className="flex justify-end">
					<LeaveCommentButton
						title="Reply"
						onClick={handleButtonClick}
					/>
				</div>
			)}
			{(commentsResult?.data?.count || 0) > defaultPageSize && (
				<div className="flex justify-end">
					<Pagination
						count={Math.ceil(
							(commentsResult?.data?.count || 0) / defaultPageSize
						)}
						page={currentPage}
						onChange={(event, page) => setCurrentPage(page)}
					/>
				</div>
			)}
		</div>
	)
}

export default CommentPaginatedList
