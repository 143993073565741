export default {
	global: {
		yes: 'Sí',
		no: 'No',
		email: 'Correo electrónico',
		password: 'Contraseña',
		resend: 'Reenviar',
		view: 'Ver',
		delete: 'Eliminar',
		created: 'Creado',
		status: 'Estado',
		reason: 'Razón',
		type: 'Tipo',
		details: 'Detalles',
		username: 'Nombre de usuario',
		retry: 'Rever',
		data: 'Datos',
	},
	auth: {
		sign_in: 'Iniciar sesión',
		dont_have_an_account: '¿No tienes una cuenta?',
		check_inbox_for_invite_from_loan_officer:
			'Por favor, revisa tu bandeja de entrada para una invitación de tu Oficial de Préstamos',
		forgot_my_password: 'Olvidé mi contraseña',
		existing_user_account_detected:
			'Se detectó una cuenta de usuario existente',
		sign_in_to_account: 'Inicia sesión en tu cuenta',
	},
	loans: {
		loan_id: 'ID del préstamo',
		not_assigned: 'No asignado',
	},
	loan_queue: {
		loan_queue: 'Cola de préstamos',
		loan_queue_record: 'Registro de cola de préstamos',
		type: {
			new_loan: 'Nuevo préstamo',
			new_cob_loan: 'Nuevo préstamo con co-prestatario',
			loan_update: 'Actualizar solicitud',
			field_update: 'Actualizar campo',
			document_upload: 'Subir documento',
			new_buckets: 'Nuevos contenedores',
		},
		job_id: 'ID de trabajo',
	},
	users: {
		loan_officer: 'Oficial de préstamos',
	},
	validation: {
		email: {
			required: 'El correo electrónico es obligatorio',
			invalid: 'El correo electrónico no es válido',
		},
		password: {
			required: 'La contraseña es obligatoria',
			minLength:
				'La contraseña debe tener al menos {minLength} caracteres',
		},
	},
	errors: {
		general: 'Hubo un error al procesar la solicitud',
		error_loading:
			'Hubo un problema al recuperar la información solicitada',
		invalid_credentials: 'Usuario y/o contraseña inválidos',
		account_doesnt_have_access_to_env:
			'Tu cuenta no tiene acceso a este entorno',
		error_retrieving_auth:
			'Error al recuperar la información de autenticación',
	},
	alerts: {
		save_success: 'El {name} se actualizó correctamente',
		delete_success: 'El {name} se eliminó correctamente',
	},
}
