import React, { useState } from 'react'
import RDataTable, { TableProps } from 'react-data-table-component'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import CancelIcon from '@mui/icons-material/Cancel'
import RefreshPNG from 'assets/lordicons/Refresh.png'
import RefreshGIF from 'assets/lordicons/RefreshAnimated.gif'
import RefreshDarkPNG from 'assets/lordicons/RefreshDark.png'
import RefreshDarkGIF from 'assets/lordicons/RefreshAnimatedDark.gif'
import clsx from 'clsx'
import { IconButton, Tooltip } from '@mui/material'
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import {
	FirstPage,
	LastPage,
	ChevronLeft,
	ChevronRight,
} from '@mui/icons-material'
import { Loading } from '../Loading'
import { Button } from '../Button'
import { useWindowSize } from '../../hooks'
import { getTheme } from '../../config'
import { isDarkMode } from '../../services/helper'
import { tableRowsPerPageOptions } from '../../services/utils'
import { ExportTable } from '../export/ExportTable'
import Heading from '../Heading'
import TextField from '../formControls/TextField'
import SubHeader from './SubHeader'

const theme = getTheme()

const bgColor = {
	'&:hover': {
		backgroundColor: 'transparent',
	},
}

export type ActionItem<T> = {
	name: string
	onClick: (_e: React.MouseEvent, _row: T) => void
	hideIf?: (_e: T) => boolean
}

export type DataTableProps<T> = {
	titleActions?: React.ReactNode
	onRefreshClick?: () => void
	addNewBtn?: {
		text?: string
		onClick?: () => void
		disabled?: boolean
	}
	exportEnabled?: boolean
	exportData?: T[]
	onSearchChange?: (_e: React.ChangeEvent<HTMLInputElement>) => void
	searchText?: string
	onClearSearchClick?: () => void
	searchFilters?: React.ReactNode
	actionItems?: ActionItem<T>[]
	refreshing?: boolean
} & TableProps<T>

const DataTable = <T,>({
	title,
	titleActions,
	onRefreshClick,
	progressComponent = <Loading size="small" />,
	progressPending,
	addNewBtn,
	exportEnabled = false,
	exportData,
	data,
	onSearchChange,
	searchText,
	onClearSearchClick,
	searchFilters,
	columns,
	actionItems = [],
	expandableRows,
	expandableRowsComponent,
	refreshing = false,
	subHeader,
	subHeaderAlign,
	subHeaderComponent,
	subHeaderWrap,
	...rest
}: DataTableProps<T>) => {
	const { dataTable: breakpoint } = theme.breakpoints
	const themeMUI = useTheme()
	const [width] = useWindowSize()
	const [actionAnchorEl, setActionAnchorEl] =
		useState<null | HTMLElement>(null)
	const [selectedActionItem, setSelectedActionItem] =
		useState<T | null>(null)

	const showActionMenu = (e: React.MouseEvent, row: T) => {
		e.stopPropagation()
		setActionAnchorEl(e.currentTarget as HTMLElement)
		setSelectedActionItem(row)
	}

	const customStyles = {
		responsiveWrapper: {
			style: {
				maxHeight: 'fit-content',
			},
		},
		headCells: {
			style: {
				backgroundColor: isDarkMode()
					? theme.color.primary.black
					: theme.color.primary.white,
			},
		},
		progress: {
			style: {
				backgroundColor: isDarkMode()
					? theme.color.primary.black
					: undefined,
			},
		},
		noData: {
			style: {
				backgroundColor: isDarkMode()
					? theme.color.primary.black
					: undefined,
				color: isDarkMode() ? theme.color.primary.white : undefined,
			},
		},
	}

	const getPaginationIcon = (IconComponent: React.ElementType) => (
		<IconComponent
			style={{
				color: isDarkMode() && theme.color.primary.white,
			}}
		/>
	)

	const hideActionMenu = () => {
		setActionAnchorEl(null)
		setTimeout(() => setSelectedActionItem(null), 500)
	}

	const onActionItemClick = (
		e: React.MouseEvent,
		item: ActionItem<T>
	) => {
		if (selectedActionItem) item.onClick(e, selectedActionItem)
		hideActionMenu()
	}

	const expanderIconStyles = isDarkMode()
		? { color: 'white' }
		: undefined

	return (
		<Box
			sx={{ width: '100%' }}
			className={
				rest.onRowClicked ? 'rdt_TableRow_Clickable' : undefined
			}
		>
			<header>
				<div
					className={clsx(
						'flex items-center mb-2',
						(title || titleActions) && addNewBtn
							? 'justify-between'
							: undefined,
						!title && !titleActions ? 'justify-end' : undefined
					)}
				>
					<div className="flex flex-row items-center">
						{title && (
							<Heading
								button={
									typeof onRefreshClick === 'function' ? (
										<IconButton
											size="large"
											edge="start"
											color="inherit"
											aria-label="refresh"
											onClick={onRefreshClick}
											disabled={progressPending}
											className="ms-10"
											sx={bgColor}
										>
											<Tooltip title="Refresh table data">
												<img
													className="w-14 mt-1"
													src={
														themeMUI.palette.mode === 'light'
															? RefreshPNG
															: RefreshDarkPNG
													}
													onMouseOver={(e) => {
														e.currentTarget.src =
															themeMUI.palette.mode === 'light'
																? RefreshGIF
																: RefreshDarkGIF
													}}
													onFocus={(e) => {
														e.currentTarget.src =
															themeMUI.palette.mode === 'light'
																? RefreshGIF
																: RefreshDarkGIF
													}}
													onMouseOut={(e) => {
														e.currentTarget.src =
															themeMUI.palette.mode === 'light'
																? RefreshPNG
																: RefreshDarkPNG
													}}
													onBlur={(e) => {
														e.currentTarget.src =
															themeMUI.palette.mode === 'light'
																? RefreshPNG
																: RefreshDarkPNG
													}}
													alt="refresh"
												/>
											</Tooltip>
										</IconButton>
									) : null
								}
							>
								{title}
							</Heading>
						)}
					</div>

					{titleActions && (
						<div className={title ? 'ms-2' : undefined}>
							{titleActions}
						</div>
					)}
					<div className="flex items-center">
						{exportEnabled && (
							<ExportTable data={exportData || data} title={title} />
						)}
						{addNewBtn && (
							<>
								{addNewBtn.onClick && (
									<Button
										text={addNewBtn.text || 'Add'}
										onClick={addNewBtn.onClick}
										disabled={addNewBtn.disabled}
									/>
								)}
								{!addNewBtn.onClick && addNewBtn}
							</>
						)}
					</div>
				</div>
			</header>
			{subHeader && (
				<SubHeader wrapContent={subHeaderWrap} align={subHeaderAlign}>
					{subHeaderComponent}
				</SubHeader>
			)}

			{(onSearchChange || searchFilters) && (
				<div className="mb-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">
					{onSearchChange && (
						<TextField
							label="Search..."
							onChange={onSearchChange}
							value={searchText}
							InputProps={
								searchText && onClearSearchClick
									? {
											endAdornment: (
												<div className="pr-2">
													<IconButton
														edge="end"
														onClick={onClearSearchClick}
														size="small"
													>
														<CancelIcon fontSize="small" />
													</IconButton>
												</div>
											),
										}
									: undefined
							}
						/>
					)}
					{searchFilters}
				</div>
			)}
			<Paper sx={{ pt: 2, mb: 2 }}>
				{refreshing && !progressPending && (
					<div className="fixed z-100 w-full">
						<Loading size="small" />
					</div>
				)}
				<RDataTable
					customStyles={customStyles}
					progressComponent={
						progressComponent || <Loading size="small" />
					}
					progressPending={progressPending}
					data={data}
					expandableRows={
						expandableRowsComponent && expandableRows === undefined
							? width < breakpoint
							: expandableRows
					}
					expandableRowsComponent={expandableRowsComponent}
					columns={
						actionItems && actionItems.length > 0
							? [
									...columns,
									{
										name: '',
										cell: (row) => (
											<IconButton
												size="small"
												edge="start"
												color="inherit"
												aria-label="refresh"
												onClick={(e) => showActionMenu(e, row)}
												sx={bgColor}
											>
												<MoreVertIcon />
											</IconButton>
										),
										width: '60px',
									},
								]
							: columns
					}
					paginationPerPage={tableRowsPerPageOptions[0]}
					paginationRowsPerPageOptions={tableRowsPerPageOptions}
					expandableIcon={{
						expanded: <ArrowDownIcon style={expanderIconStyles} />,
						collapsed: <ArrowRightIcon style={expanderIconStyles} />,
					}}
					paginationIconFirstPage={getPaginationIcon(FirstPage)}
					paginationIconLastPage={getPaginationIcon(LastPage)}
					paginationIconPrevious={getPaginationIcon(ChevronLeft)}
					paginationIconNext={getPaginationIcon(ChevronRight)}
					{...rest}
				/>
			</Paper>
			{actionItems && (
				<Menu
					id="menu-appbar"
					anchorEl={actionAnchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(actionAnchorEl)}
					className="mt-8 sm:mt-10"
					onClose={hideActionMenu}
				>
					{actionItems.map((item, index) => {
						if (
							typeof item.hideIf === 'function' &&
							selectedActionItem &&
							item.hideIf(selectedActionItem)
						)
							return false
						return (
							<MenuItem
								key={index}
								onClick={(e) => onActionItemClick(e, item)}
							>
								{item.name}
							</MenuItem>
						)
					})}
				</Menu>
			)}
		</Box>
	)
}

export default DataTable
