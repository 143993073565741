// NPM packages
import React, { useEffect, useRef, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import SwipeableViews from 'react-swipeable-views'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { useTheme } from '@mui/material/styles'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useMutation } from '@tanstack/react-query'
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Menu,
	Typography,
} from '@mui/material'
import Dialog from '../../../components/modals/Dialog'
import { isEqual } from 'lodash'
// Components
import { useAppContext } from '../../../components/AppContext'
import Page from '../../../components/Page'
import ChangePassword from '../../../components/ChangePassword'
import { LoadingBtn } from '../../../components/Button'
import { ModalUploadSizeLimit } from '../../../components/modals/ModalUploadSizeLimit'
import { ImagePicker } from '../../../components/admin/WebsiteSettings'
import { TextEditor } from '../../../components/formControls/TextEditor'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import { ModalDeleteMe } from '../../../components/modals/ModalDeleteMe'

import UserProfileSettings from './UserProfileSettings'
// Services
import {
	a11yProps,
	getErrorMessage,
	isEmailValid,
} from '../../../services/helper'
import { Roles } from '../../../services/client'
import { applyMask, fileMBSizes } from '../../../services/utils'
import { useAlert, useWindowSize } from '../../../hooks'
import { getTheme } from '../../../config'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'
import StateLicensesList from '../../../components/StateLicensesList'
import UserProfileForm from './forms/UserProfileForm'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'
import { LayoutRow } from '../../../components/layout'
import MobilePhoneVerificationStepper from '../../../components/MobilePhoneVerificationStepper'
import { Switch } from '../../../components/formControls'
import SmsOptInForm from './forms/SmsOptInForm'
import WarningIcon from '@mui/icons-material/Warning'
import { formatPhoneWithCountryCode } from '../../../services/formattingUtils'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <div className="mt-5">{children}</div>}
		</div>
	)
}

const theme = getTheme()

export default function UserProfile({ navigation }) {
	const muiTheme = useTheme()
	const [width] = useWindowSize()
	const { alert } = useAlert()
	const { state } = useAppContext()
	const initialRender = useRef(true)
	const mixpanel = useMixpanel()
	const { user, siteConfig } = state

	const [tab, setTab] = useState(0)
	const [userPassword, setUserPassword] = useState('')
	const [oldPassword, setOldPassword] = useState('')
	const [passwordConfirmed, setPasswordConfirmed] = useState('')
	const [enabledButton, setEnabledButton] = useState(true)
	const [showOldPassword, setShowOldPassword] = useState(false)
	const userObject = {
		...siteConfig,
		...user,
		id: siteConfig.id,
	}
	const [initialState, setInitialState] = useState(userObject)
	const [formData, setFormData] = useState(userObject)
	const [modalSizeLimitVisible, setModalSizeLimitVisible] =
		useState(false)
	const [inValidFields, setInValidFields] = useState([])
	const [canBeSubmitted, setCanBeSubmitted] = useState(true)
	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: '',
		leavePage: false,
		canBeSaved: false,
		isSave: false,
		event: null,
	})
	const [modalDeleteMeOpen, setModalDeleteMeOpen] = useState(false)
	const [isModalEditMeOpen, setIsModalEditMeOpen] = useState(false)
	const [isModalEditPhoneOpen, setIsModalEditPhoneOpen] =
		useState(false)
	const [isModalSmsOptInOpen, setIsModalSmsOptInOpen] =
		useState(false)
	const [isActionMenuMeOpen, setIsActionMenuMeOpen] = useState(false)
	const [isActionMenuPhoneOpen, setIsActionMenuPhoneOpen] =
		useState(false)
	const [actionAnchorEl, setActionAnchorEl] = useState(null)

	useEffect(() => {
		const { domain, ...rest } = formData
		const canBeSubmitted =
			formData?.url?.length > 0 &&
			isEmailValid(formData?.email?.trim()) &&
			// formData?.phone?.replace(/\D/g, "")?.length === 10 &&
			formData?.firstName?.trim()?.length > 0 &&
			formData?.lastName?.trim()?.length > 0 &&
			formData?.title?.trim()?.length > 0 &&
			formData?.licenses?.length > 0 &&
			// formData?.branchID?.length > 0 &&
			!isEqual(initialState, rest)

		setCanBeSubmitted(canBeSubmitted)

		if (initialRender.current) {
			initialRender.current = false
		}
	}, [formData, initialState])

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const updatePOSSiteConfiguration = (field, value) => {
		setFormData((formData) => ({ ...formData, [field]: value }))
	}

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result)
			}
			fileReader.onerror = (error) => {
				reject(error)
			}
		})
	}

	const handleIntroductionChange = (newIntroduction) => {
		if (newIntroduction !== formData.introduction) {
			setFormData({
				...formData,
				introduction: newIntroduction,
			})
		}
	}

	const handleImagePicker = async (e, field) => {
		const file = e.target.files[0]

		if (file?.size > fileMBSizes['20']) {
			setModalSizeLimitVisible(true)
		} else {
			const base64 = await convertBase64(file)
			setFormData({ ...formData, [field]: base64 })
		}
	}

	const handleOnChangeText = (text, nativeID, field, action) => {
		if (field === 'phone' || field === 'tollFree') {
			text = applyMask(text, 'phone')
		}

		if (action) action(text)
		if (field)
			updatePOSSiteConfiguration(field, text.trim().toLowerCase())

		if (text) {
			setInValidFields(
				inValidFields.filter((item) => item !== nativeID)
			)
		} else {
			setInValidFields([nativeID, ...inValidFields])
			handleInputValid(text)
		}
	}

	const handleInputValid = (id) => {
		return inValidFields.includes(id)
	}

	const updateBioTitle = (text) =>
		setFormData((formData) => ({
			...formData,
			introductionTitle: text,
		}))

	const updateSiteName = (text) =>
		setFormData((formData) => ({ ...formData, name: text }))

	const handleCheckFormLicenses = () => {
		formData.licenses.length === 0
			? setModalConfirmation({
					open: true,
					text: theme.modal.licensesText,
					leavePage: false,
					canBeSaved: false,
					isSave: true,
					event: null,
				})
			: saveForm()
	}

	const modalConfirm = async (modalConfirmation) => {
		modalConfirmation.leavePage
			? navigation.dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation({
					open: false,
					text: '',
					leavePage: false,
				})
	}

	const openDeleteMeModal = () => {
		setIsActionMenuMeOpen(false)
		setModalDeleteMeOpen(true)
	}

	const openModalEditMe = () => {
		setIsActionMenuMeOpen(false)
		setIsModalEditMeOpen(true)
	}

	const closeModalEditMe = () => {
		setIsModalEditMeOpen(false)
	}

	const openModalEditPhone = () => {
		setIsActionMenuMeOpen(false)
		setIsModalEditPhoneOpen(true)
	}

	const closeModalEditPhone = () => {
		setIsModalEditPhoneOpen(false)
	}

	const openModalSmsOptIn = () => {
		setIsModalSmsOptInOpen(true)
	}

	const closeModalSmsOptIn = () => {
		setIsModalSmsOptInOpen(false)
	}

	const openActionMenuMe = (e) => {
		setIsActionMenuMeOpen(false)
		setActionAnchorEl(e.currentTarget)
		setIsActionMenuMeOpen(true)
	}

	const closeActionMenuMe = () => {
		setIsActionMenuMeOpen(false)
		setActionAnchorEl(null)
	}

	const openActionMenuPhone = (e) => {
		setIsActionMenuPhoneOpen(false)
		setActionAnchorEl(e.currentTarget)
		setIsActionMenuPhoneOpen(true)
	}

	const closeActionMenuPhone = () => {
		setIsActionMenuPhoneOpen(false)
		setActionAnchorEl(null)
	}

	const changePasswordMutation = useMutation({
		mutationFn: ({ oldPassword, userPassword }) =>
			TheBigPOSApi.changePassword({
				oldPassword,
				newPassword: userPassword,
			}),
	})

	const deleteUserMutation = useMutation({
		mutationFn: (data) => TheBigPOSApi.deleteMe(data),
	})

	const updateUserMutation = useMutation({
		mutationFn: (data) => TheBigPOSApi.replaceMe(data),
	})

	const updateLoanOfficerSiteConfigurationMutation = useMutation({
		mutationFn: ({ data, loanOfficerId, siteConfigurationId }) =>
			TheBigPOSApi.replaceLoanOfficerSiteConfiguration(
				loanOfficerId,
				siteConfigurationId,
				data
			),
	})

	const updatePartnerSiteConfigurationMutation = useMutation({
		mutationFn: ({ data, realtorId, siteConfigurationId }) =>
			TheBigPOSApi.replacePartnerSiteConfiguration(
				realtorId,
				siteConfigurationId,
				data
			),
	})

	const saveForm = async () => {
		try {
			const {
				id: formId,
				entityID,
				email,
				firstName,
				lastName,
				title,
				notificationSettings,
			} = formData

			const { phone } = user

			if (formId) {
				await updateUserMutation.mutateAsync({
					id: entityID,
					email,
					phone:
						phone.slice(0, 2) === '+1'
							? phone
							: formatPhoneWithCountryCode(phone),
					firstName,
					lastName,
					title,
					notificationSettings,
				})
				if (Roles.loanOfficer === user.role)
					await updateLoanOfficerSiteConfigurationMutation.mutateAsync(
						{
							data: formData,
							loanOfficerId: entityID,
							siteConfigurationId: formId,
						}
					)
				else if (Roles.realtor === user.role)
					await updatePartnerSiteConfigurationMutation.mutateAsync({
						data: formData,
						realtorId: entityID,
						siteConfigurationId: formId,
					})
				setInitialState(formData)
				alert('Your profile has been updated', {
					severity: 'success',
				})
			}
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleChangePassword = async () => {
		try {
			await changePasswordMutation.mutateAsync({
				oldPassword,
				userPassword,
			})
			setOldPassword('')
			setUserPassword('')
			alert('Account password successfully changed')
			mixpanel.trackEvent(eventTypes.USER_PASSWORD_UPDATED)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleDeleteSubmit = async (feedback) => {
		try {
			await deleteUserMutation.mutateAsync(feedback)
			alert('Request for deletion was successfully sent', {
				severity: 'success',
			})
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleDeleteClose = async () => {
		setModalDeleteMeOpen(false)
	}

	const handleUserProfileSave = async (data) => {
		try {
			const updateUserRequestObject = {
				...formData,
				...data,
				notificationSettings: {
					emailEnabled: data.emailEnabled,
					textEnabled: data.textEnabled,
				},
				phone:
					formData.phone.slice(0, 2) === '+1'
						? formData.phone
						: formatPhoneWithCountryCode(formData.phone),
			}
			await updateUserMutation.mutateAsync(updateUserRequestObject)
			setInitialState(updateUserRequestObject)
			setFormData(updateUserRequestObject)
			setIsModalEditMeOpen(false)
			setIsActionMenuMeOpen(false)
			alert('Your profile has been updated', {
				severity: 'success',
			})
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleSmsOptInSubmit = async () => {
		try {
			const updateUserRequestObject = {
				...formData,
				notificationSettings: {
					...formData.notificationSettings,
					textEnabled: true,
					textOptIn: true,
				},
			}

			await updateUserMutation.mutateAsync(updateUserRequestObject)

			setInitialState(updateUserRequestObject)
			setFormData(updateUserRequestObject)
			setIsModalSmsOptInOpen(false)
			setIsActionMenuMeOpen(false)
			alert('Your SMS settings have been updated', {
				severity: 'success',
			})
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleSmsOptOutSubmit = async () => {
		try {
			const updateUserRequestObject = {
				...formData,
				notificationSettings: {
					...formData.notificationSettings,
					textOptIn: false,
				},
			}
			await updateUserMutation.mutateAsync(updateUserRequestObject)
			setInitialState(updateUserRequestObject)
			setFormData(updateUserRequestObject)
			setIsModalSmsOptInOpen(false)
			setIsActionMenuMeOpen(false)
			alert('Your SMS settings have been updated', {
				severity: 'success',
			})
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleNotificationChange = async (type) => {
		try {
			const updateUserRequestObject = {
				...formData,
				phone: user.phone,
				notificationSettings: {
					...formData.notificationSettings,
					emailEnabled:
						type === 'email'
							? !formData.notificationSettings.emailEnabled
							: formData.notificationSettings.emailEnabled,
					textEnabled:
						type === 'text'
							? !formData.notificationSettings.textEnabled
							: formData.notificationSettings.textEnabled,
				},
			}
			await updateUserMutation.mutateAsync(updateUserRequestObject)
			setInitialState(updateUserRequestObject)
			setFormData(updateUserRequestObject)
			setIsModalEditMeOpen(false)
			setIsActionMenuMeOpen(false)
			alert(`Your ${type} settings have been updated`, {
				severity: 'success',
			})
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleEmailNotificationChange = async () => {
		await handleNotificationChange('email')
	}

	const handleTextNotificationChange = async () => {
		await handleNotificationChange('text')
	}

	const handleMobilePhoneVerificationStepperComplete = () => {
		setFormData({
			...formData,
			...user,
		})
		closeModalEditPhone()
	}

	return (
		<Page page="app-profile" title="My Profile" isFullWidth>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
			/>
			<ModalUploadSizeLimit
				visible={modalSizeLimitVisible}
				closeModal={() => setModalSizeLimitVisible(false)}
			/>

			<ModalDeleteMe
				open={modalDeleteMeOpen}
				onSubmit={handleDeleteSubmit}
				onClose={handleDeleteClose}
			/>
			<Dialog
				title="Edit Personal Information"
				open={isModalEditMeOpen}
				onClose={closeModalEditMe}
				fullWidth
			>
				<UserProfileForm
					user={{
						phone: user.phone,
						forcedPasswordReset: formData.forcedPasswordReset,
						mfaEnabled: formData.mfaEnabled,
						firstName: formData.firstName,
						lastName: formData.lastName,
						title: formData.title,
						notificationSettings: {
							emailEnabled:
								formData.notificationSettings?.emailEnabled,
							textEnabled: formData.notificationSettings?.textEnabled,
						},
					}}
					onSubmit={handleUserProfileSave}
				/>
			</Dialog>
			<Dialog
				title="Edit Phone Number"
				open={isModalEditPhoneOpen}
				onClose={closeModalEditPhone}
				fullWidth
			>
				<MobilePhoneVerificationStepper
					onComplete={handleMobilePhoneVerificationStepperComplete}
					isMfaRequest={false}
				/>
			</Dialog>

			<Dialog
				title="Update SMS Opt-in Status"
				open={isModalSmsOptInOpen}
				onClose={closeModalSmsOptIn}
				fullWidth
			>
				<SmsOptInForm
					textOptInStatus={formData?.notificationSettings?.textOptIn}
					onSubmit={
						formData?.notificationSettings?.textOptIn
							? handleSmsOptOutSubmit
							: handleSmsOptInSubmit
					}
				/>
			</Dialog>

			<div className="pl-5 pr-5 pb-10 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
					My Profile
				</p>
				<div className="flex flex-wrap justify-end my-5">
					<LoadingBtn
						id="AdminLoanOfficerEditSaveButton"
						disabled={!canBeSubmitted}
						loading={updateUserMutation.isPending}
						text="Update My Profile"
						onClick={handleCheckFormLicenses}
						fullWidth={false}
					/>
				</div>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={tab}
					onChange={handleTabChange}
					aria-label="Tabs"
				>
					<Tab label="Personal Information" {...a11yProps(0)} />
					<Tab label="Security" {...a11yProps(1)} />
					<Tab
						label={`${[Roles.loanOfficer, Roles.realtor].includes(user.role) ? 'Website ' : ''}Settings`}
						{...a11yProps(2)}
					/>
					{[Roles.loanOfficer, Roles.realtor].includes(user.role) && (
						<Tab label="Licensing" {...a11yProps(3)} />
					)}
				</Tabs>
				<SwipeableViews
					axis={muiTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tab}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={tab} index={0} dir={muiTheme.direction}>
						<LayoutRow>
							<Card>
								<CardContent>
									<div className="relative">
										<div className="absolute top-0 right-0">
											<IconButton
												size="small"
												edge="start"
												color="inherit"
												aria-label="refresh"
												onClick={openActionMenuMe}
												sx={{
													'&:hover': {
														backgroundColor: 'transparent',
													},
												}}
											>
												<MoreVertIcon />
											</IconButton>
											<div>
												<Menu
													id="menu-appbar"
													anchorEl={actionAnchorEl}
													anchorOrigin={{
														vertical: 'top',
														horizontal: 'right',
													}}
													keepMounted
													transformOrigin={{
														vertical: 'top',
														horizontal: 'right',
													}}
													open={isActionMenuMeOpen}
													className="mt-8 sm:mt-10"
													onClose={closeActionMenuMe}
												>
													<MenuItem onClick={openModalEditMe}>
														Edit Information
													</MenuItem>

													<MenuItem onClick={openDeleteMeModal}>
														Delete
													</MenuItem>
												</Menu>
											</div>
										</div>
										<div className="mb-2">
											<span className="font-bold">First Name:</span>{' '}
											{formData.firstName}
										</div>
										<div className="mb-2">
											<span className="font-bold">Last Name:</span>{' '}
											{formData.lastName}
										</div>
										<div>
											<span className="font-bold">Title:</span>{' '}
											{formData.title}
										</div>
									</div>
								</CardContent>
							</Card>
						</LayoutRow>
						<LayoutRow>
							<Card>
								<CardContent>
									<div className="relative">
										<div className="absolute top-0 right-0">
											<IconButton
												size="small"
												edge="start"
												color="inherit"
												aria-label="refresh"
												onClick={openActionMenuPhone}
												sx={{
													'&:hover': {
														backgroundColor: 'transparent',
													},
												}}
											>
												<MoreVertIcon />
											</IconButton>
											<div>
												<Menu
													id="menu-appbar"
													anchorEl={actionAnchorEl}
													anchorOrigin={{
														vertical: 'top',
														horizontal: 'right',
													}}
													keepMounted
													transformOrigin={{
														vertical: 'top',
														horizontal: 'right',
													}}
													open={isActionMenuPhoneOpen}
													className="mt-8 sm:mt-10"
													onClose={closeActionMenuPhone}
												>
													<MenuItem onClick={openModalEditPhone}>
														Edit{!formData.phoneVerified && '/Verify'}{' '}
														Phone
													</MenuItem>
												</Menu>
											</div>
										</div>
										<div className="mb-2">
											<span className="font-bold">
												Email Address:
											</span>{' '}
											{formData.email}
										</div>
										<div>
											<span className="font-bold">
												Mobile Phone:&nbsp;
											</span>
											{formData.phone && (
												<>
													{applyMask(
														formData.phone.replace('+1', ''),
														'phone'
													)}
													{formData.phoneVerified ? (
														<span className="text-green-500">
															{' '}
															(Verified)
														</span>
													) : (
														<span className="text-red-500">
															{' '}
															(Not Verified)
														</span>
													)}
												</>
											)}
										</div>
									</div>
								</CardContent>
							</Card>
						</LayoutRow>
						<LayoutRow>
							<Card>
								<CardContent>
									<div className="relative">
										<div className="mb-2">
											<span className="font-bold">
												Email Notifications:
											</span>

											<Switch
												name="emailEnabled"
												label={
													formData?.notificationSettings?.emailEnabled
														? 'Email Enabled'
														: 'Email Disabled'
												}
												checked={
													formData?.notificationSettings?.emailEnabled
												}
												onChange={handleEmailNotificationChange}
												disabled={updateUserMutation.isPending}
											/>
										</div>
										{formData?.accountSettings?.isSmsEnabled && (
											<div>
												<span className="font-bold">
													SMS Notifications:
												</span>
												<div>
													<LayoutRow className="md:flex gap-2 items-center">
														<Switch
															name="textEnabled"
															label={
																formData?.notificationSettings
																	?.textEnabled
																	? 'SMS Enabled'
																	: 'SMS Disabled'
															}
															checked={
																formData.phoneVerified &&
																formData?.notificationSettings
																	?.textEnabled
															}
															onChange={
																formData?.notificationSettings
																	?.textEnabled ||
																formData?.notificationSettings
																	?.textOptIn
																	? handleTextNotificationChange
																	: openModalSmsOptIn
															}
															disabled={
																updateUserMutation.isPending ||
																!formData.phoneVerified
															}
														/>{' '}
													</LayoutRow>
													{!formData.phoneVerified && (
														<div>
															<p className="md:inline-flex items-center gap-2">
																<span>
																	To start receiving SMS messages:
																</span>
																<button
																	className="mt-[0.125rem]"
																	onClick={openModalEditPhone}
																>
																	<Typography variant="link">
																		Verify Your Phone Number
																	</Typography>
																</button>
															</p>
														</div>
													)}
													{formData?.phoneVerified &&
														(formData.notificationSettings
															?.textEnabled ||
															formData.notificationSettings
																?.textOptIn) && (
															<div>
																<p className="md:inline-flex items-center gap-2">
																	<span className="md:inline-flex items-center gap-2">
																		{!formData.notificationSettings
																			?.textOptIn && (
																			<span className="block text-center md:inline text-red-600">
																				<WarningIcon />
																			</span>
																		)}

																		<span>
																			{formData.notificationSettings
																				?.textOptIn
																				? 'To stop receiving SMS messages: '
																				: `To start receiving SMS messages: `}
																		</span>
																	</span>
																	<button
																		className="mt-[0.125rem]"
																		onClick={openModalSmsOptIn}
																	>
																		<Typography variant="link">
																			{formData.notificationSettings
																				?.textOptIn
																				? 'Opt-out here'
																				: 'Opt-in here'}
																		</Typography>
																	</button>
																</p>
															</div>
														)}
												</div>
											</div>
										)}
									</div>
								</CardContent>
							</Card>
						</LayoutRow>
					</TabPanel>
					<TabPanel value={tab} index={1} dir={muiTheme.direction}>
						<Card>
							<CardContent>
								<div className="flex flex-col-reverse md:flex-row gap-10">
									<div>
										<h3 className="text-center">Update Password</h3>
										<div className="flex flex-col items-center mt-10">
											<TextField
												onChange={(e) =>
													setOldPassword(e.target.value)
												}
												value={oldPassword}
												label={oldPassword === '' ? null : 'Password'}
												placeholder="Old Password"
												id="ChangePasswordInput"
												variant="standard"
												type={showOldPassword ? 'text' : 'password'}
												className="w-64 mx-2.5 sm:w-96 sm:px-12"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={() =>
																	setShowOldPassword(!showOldPassword)
																}
																onMouseDown={() =>
																	setShowOldPassword(!showOldPassword)
																}
															>
																{showOldPassword ? (
																	<VisibilityIcon />
																) : (
																	<VisibilityOffIcon />
																)}
															</IconButton>
														</InputAdornment>
													),
												}}
											/>
										</div>
										<ChangePassword
											id="ChangePassword"
											passwordLabel="New Password"
											confirmPasswordLabel="Confirm password"
											userPassword={userPassword}
											setUserPassword={setUserPassword}
											setConfirmButton={setEnabledButton}
											confirmButtonEnabled={enabledButton}
											passwordConfirmed={passwordConfirmed}
											setPasswordConfirmed={setPasswordConfirmed}
										/>
										<div className="flex flex-col items-center mt-5">
											<div className="w-64 mx-2.5 sm:w-96 sm:px-12">
												<LoadingBtn
													id="ChangePasswordButton"
													disabled={
														!enabledButton ||
														!oldPassword ||
														changePasswordMutation.isPending
													}
													loading={changePasswordMutation.isPending}
													onClick={handleChangePassword}
													fullWidth
													variant="contained"
													text="Change Password"
												/>
											</div>
										</div>
									</div>
									<div className="flex flex-col items-center">
										<UserProfileSettings />
									</div>
								</div>
							</CardContent>
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={2} dir={muiTheme.direction}>
						<Card>
							<CardContent>
								<div className="flex">
									<div className="w-full md:ml-5 md:w-1/2 md:mx-2 md:mb-0 md:max-w-screen-sm">
										<TextField
											id="AdminLoanOfficerProfileSiteNameInput"
											placeholder="Site Name (required)"
											label="Site Name *"
											value={formData.name || ''}
											onChange={(e) =>
												handleOnChangeText(
													e.target.value,
													'AdminLoanOfficerProfileSiteNameInput',
													null,
													updateSiteName
												)
											}
											error={handleInputValid(
												'AdminLoanOfficerProfileSiteNameInput'
											)}
											variant="standard"
											className="w-full sm:px-12"
											sx={{ mb: 2 }}
										/>
										<ImagePicker
											title="Headshot (300 x 300)"
											value={formData?.profilePhotoUrl}
											handleImagePicker={handleImagePicker}
											field="profilePhotoUrl"
											id="WebsiteSettingsUpdateProfilePhotoImageButton"
										/>
										{[Roles.loanOfficer, Roles.realtor].includes(
											user.role
										) && (
											<>
												<div className="flex flex-row w-full items-end">
													<FormControl
														key="WebsiteSettingsLandingPageTypePicker"
														variant="standard"
														className="w-full sm:px-12"
														sx={{ mb: 2, mr: 2 }}
													>
														<InputLabel>Landing Page Type</InputLabel>
														<Select
															value={formData?.landingPageType || 0}
															onChange={(e) =>
																updatePOSSiteConfiguration(
																	'landingPageType',
																	e.target.value
																)
															}
															label="Landing Page Type"
														>
															<MenuItem value={0}>
																Default Landing Page
															</MenuItem>
															<MenuItem value={1}>
																Condensed Landing Page
															</MenuItem>
														</Select>
													</FormControl>
												</div>
												<div
													className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
												>
													<TextField
														id="AdminAccountWebsiteSettingsFacebookUrl"
														placeholder="Enter Facebook URL"
														value={formData?.facebookUrl}
														label="Facebook"
														onChange={(e) =>
															updatePOSSiteConfiguration(
																'facebookUrl',
																e.target.value
															)
														}
														variant="standard"
														className="w-full sm:px-12"
														sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
													/>
													<TextField
														id="AdminAccountWebsiteSettingsTwitterUrl"
														placeholder="Enter Twitter URL"
														value={formData?.twitterUrl}
														label="Twitter"
														onChange={(e) =>
															updatePOSSiteConfiguration(
																'twitterUrl',
																e.target.value
															)
														}
														variant="standard"
														className="w-full sm:px-12"
														sx={{ mb: 2 }}
													/>
												</div>
												<div
													className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
												>
													<TextField
														id="AdminAccountWebsiteSettingsInstagramUrl"
														placeholder="Enter Instagram URL"
														value={formData?.instagramUrl}
														label="Instagram"
														onChange={(e) =>
															updatePOSSiteConfiguration(
																'instagramUrl',
																e.target.value
															)
														}
														variant="standard"
														className="w-full sm:px-12"
														sx={{ mb: 2, mr: width > 468 ? 2 : 0 }}
													/>
													<TextField
														id="AdminAccountWebsiteSettingsLinkedInUrl"
														placeholder="Enter LinkedIn URL"
														value={formData?.linkedInUrl}
														label="LinkedIn"
														onChange={(e) =>
															updatePOSSiteConfiguration(
																'linkedInUrl',
																e.target.value
															)
														}
														variant="standard"
														className="w-full sm:px-12"
														sx={{ mb: 2 }}
													/>
												</div>
											</>
										)}
									</div>
									{[Roles.loanOfficer, Roles.realtor].includes(
										user.role
									) && (
										<div className="w-full md:ml-5 md:w-1/2 md:mx-2 md:mb-0 md:max-w-screen-sm">
											<TextField
												id="AdminLoanOfficerBioTitleInput"
												placeholder="My Bio Title"
												value={formData.introductionTitle || ''}
												label="Bio Title"
												onChange={(e) =>
													handleOnChangeText(
														e.target.value,
														'AdminLoanOfficerBioTitleInput',
														null,
														updateBioTitle
													)
												}
												variant="standard"
												className="w-full sm:px-12"
												sx={{ mb: 2 }}
											/>

											<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm">
												Bio
											</p>
											<TextEditor
												height="600px"
												placeholder="Type something..."
												initialValue={formData.introduction}
												onChange={handleIntroductionChange}
											/>
										</div>
									)}
								</div>
							</CardContent>
						</Card>
					</TabPanel>
					{[Roles.loanOfficer, Roles.realtor].includes(user.role) && (
						<TabPanel value={tab} index={3} dir={muiTheme.direction}>
							<Card>
								<CardContent>
									<StateLicensesList
										grid={true}
										licenses={formData.licenses}
									/>
								</CardContent>
							</Card>
						</TabPanel>
					)}
				</SwipeableViews>
			</div>
		</Page>
	)
}
